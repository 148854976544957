body {
  font-family: century-gothic;
  margin: 0;
  background-color: #222222;
}

ul {
  list-style: none;
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666;
  transition: all 0.3s;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* TODO  remove when react-error-overlay gets a fix in the future */
body > iframe {
  display: none;
}

/* Add line breaks with /n */

.MuiDialog-container {
  p {
    white-space: break-spaces;
  }
}

/**
 * Disable bottom right footer on MapboxGL since we render our own datasource
 *
 * https://docs.mapbox.com/help/getting-started/attribution/
 * > If you are using Mapbox software to render a map based entirely on data
 * from a non-Mapbox source, you may omit the text attribution but must still
 * display the Mapbox logo.
 **/
.mapboxgl-ctrl-bottom-right {
  opacity: 0.4;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
  background: black;
  border-radius: 4px 0 0 0;
}

.mapboxgl-ctrl-bottom-right a {
  color: white;
}

.mapboxgl-ctrl-bottom-right button {
  display: none;
}
